import "./App.css";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { useEffect, useState } from "react";
import React from "react";

import Error from "./pages/special/error";
import Thankyou from "./pages/special/thankyou";

import IndexFour from "./pages/index/index-four";

import Faqs from "./pages/aboutus/faqs";
import PrivacyPolicy from "./pages/privacy-policy";

import ScrollToTop from "./components/scroll-top";

import "swiper/swiper.scss";
import "swiper/components/navigation/navigation.scss";
import "swiper/components/pagination/pagination.scss";
import "swiper/components/scrollbar/scrollbar.scss";
import "swiper/swiper-bundle.min.css";
import "./i18next/i18next";
import WhyInvestCrypto from "./pages/education/WhyInvestCrypto";
import CryptoDictionary from "./pages/education/CryptoDictionary";
import WhyCloudex from "./pages/aboutus/whyCloudex";
import AiTrading from "./pages/aboutus/aiTrading";
import KVKK from "./pages/kvkk";
import Account from "./pages/accounts/Account";
import CookiePolicy from "./pages/cookiePolicy";
import VisionMision from "./pages/visionMision";
import CloudexInsurance from "./pages/aboutus/cloudexInsurance";
import Awards from "./pages/aboutus/Awards";
import "./i18next/i18next";
import AboutCeo from "./pages/aboutus/AboutCeo";
import References from "./pages/References";
import WhyBlockchain from "./pages/education/WhyBlockchain";
import Announcements from "./pages/announcements/Announcements";
import News from "./pages/news/News";
import Popup from "./assets/images/burning.png"

function App() {
	const [showPopup, setShowPopup] = useState(false);
	useEffect(() => {
		document.documentElement.setAttribute("dir", "ltr");
		document.documentElement.classList.add("dark");
		document.body.classList.add(
			"font-urbanist",
			"text-base",
			"text-black",
			"dark:text-white",
			"dark:bg-slate-900"
		);
	});
	// Sayfa açıldığında ekrana popup çıkarma
	useEffect(() => {
		// Kullanıcının popup'ı daha önce görüp görmediğini kontrol et
		const isPopupShown = localStorage.getItem('popupShownv2');
		if (!isPopupShown) {
			setShowPopup(true);
		}
	}, []);
	const handleClosePopup = () => {
		// Popup kapatıldığında localStorage'a kaydet
		localStorage.setItem('popupShownv2', 'true');
		setShowPopup(false);
	};
	return (
		<BrowserRouter>
			{showPopup && (
				<div className="ad-popup">
					<div className="ad-popup-inner">
						<button style={{ position: "absolute", right: "10px", top: "10px" }} onClick={handleClosePopup}>
							<i className="fa-solid fa-xmark text-light fa-xl"></i>
						</button>
						<img src={Popup} alt="Popup Image" />
					</div>
				</div>
			)}
			<ScrollToTop />
			<Routes>
				<Route path="/" element={<IndexFour />} />
				<Route path="/faqs" element={<Faqs />} />
				<Route
					path="/why-invest-cryptocurrencies"
					element={<WhyInvestCrypto />}
				/>
				<Route path="/why-blockchain-important" element={<WhyBlockchain />} />
				<Route path="/crypto-dictionary" element={<CryptoDictionary />} />
				<Route path="/why-cloudex" element={<WhyCloudex />} />
				<Route path="/about-ceo" element={<AboutCeo />} />
				<Route path="/announcements" element={<Announcements />} />
				<Route path="/news" element={<News />} />
				<Route path="/references" element={<References />} />
				<Route path="/privacy-policy" element={<PrivacyPolicy />} />
				<Route path="/gdpr" element={<KVKK />} />
				<Route path="/account" element={<Account />} />
				<Route path="/cookie-policy" element={<CookiePolicy />} />
				<Route path="/vision-and-mision" element={<VisionMision />} />
				<Route path="/cloudex-insurance" element={<CloudexInsurance />} />
				<Route path="/thankyou" element={<Thankyou />} />
				<Route path="/awards" element={<Awards />} />
				<Route path="/index" element={<Navigate replace to="/" />} />
				<Route path="/ai-trading" element={<AiTrading />} />
				<Route path="*" element={<Error />} />
			</Routes>
		</BrowserRouter>
	);
}

export default App;
